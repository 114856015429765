import axiosApi from 'axios'
let headers;
if(localStorage.token)
{
    headers = {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.token}`,
    };
}
else{
    headers = {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    };  
}


const axios = axiosApi.create({
  baseURL: process.env.VUE_APP_API,
  headers:headers
});

export default axios;