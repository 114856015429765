<template>

      <router-view/>
  
</template>
<style>
 .bg-purple{
  background: #6739B7;
  background-image: linear-gradient(to right top,#3a2066,#422575,#4a2983,#532e93,#5b33a2,#5b33a2,#5b33a1,#5b33a1,#522e91,#4a2a82,#412573,#392164);
  color:white ;
 }
.page-link{
  background: #6739B7 !important;
  color:white !important;
}
 #main{
  position: relative;
  height:auto;
 }


 .icons{
  height: 32px;
  width: 32px;
 }
 #backIcon{
  height: 24px;
  width: 24px;
  padding: 1px;
 }
 
</style>
